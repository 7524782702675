import React from "react"
import LangContext from "@contexts/lang"
import Flex from "../Flex"
import Typography from "../Typography"
import Button from "../Button"
import Feature from "./Feature"
import arrowLeftSvg from "@images/arrow-left.svg"
import { gaPlanCheckout, gaSetCurrency } from "@utils/analytics"
import formatMessage from "@utils/texts"
import {pricePerMonth} from "@utils/pricePerMonth"

function Plan(
  {
    activePaymentMethod,
    activePlanId,
    currencyUnit,
    description,
    discountText,
    features,
    interval,
    locale,
    onPlanSelection,
    paymentMethods,
    previousPlanTitle,
    price,
    showPaymentActions,
    id,
    title,
    descriptionEn,
    showSignupButton = true,
    ...props
  },
  ref
) {

  const { lang } = React.useContext(LangContext)

  function handlePlanSelection(paymentMethod) {
    gaSetCurrency(currencyUnit)
    gaPlanCheckout({
      id,
      name: title,
      variant: interval,
      price,
      category: "Plans",
      paymentMethod,
    })
    onPlanSelection({ id, paymentMethod })
  }

  const monthlyPrice = pricePerMonth(interval,price)

  return (
    <Flex
      style={{
        boxShadow: "0 1px 4px 1px rgba(207, 216, 220, 0.33)",
        borderColor: "white",
        borderRadius: 4,
      }}
      flexDirection="column"
      p={3}
      {...props}
      ref={ref}
    >
      <Flex alignItems="center" flexDirection="column" flex={1}>
        <Typography textAlign="center" fontWeight="bold" mb={1} fontSize="18px">
          {title}
        </Typography>
        <Typography
          mb={3}
          textAlign="center"
          fontSize="14px"
          fontWeight="500"
          color="grays.subtitle"
        >
          {interval}
        </Typography>
        <Flex as="ul" alignSelf="flex-start" flexDirection="column" mb={1}>
          {previousPlanTitle && (
            <Feature
              flipIconInRtl
              iconSrc={arrowLeftSvg}
              textStyle={{ fontWeight: "bold" }}
            >
              {`${formatMessage({
                id: "everythingInPrevious",
                lang,
              })} ${previousPlanTitle} ${formatMessage({
                id: "plus",
                lang,
              })}`}
            </Feature>
          )}
          {features.map((feature, i) => (
            <Feature key={i}>{feature}</Feature>
          ))}
        </Flex>
        <Flex alignItems="center" mb={discountText ? "3px" : 3} mt="auto">
          <Typography fontSize="16px" fontWeight="600" mr="4px">
            {locale === "ar"
              ? `${monthlyPrice} ${currencyUnit}`
              : `${currencyUnit} ${monthlyPrice}`}
          </Typography>
          <Typography color="grays.subtitle" fontSize="16px">
            / {formatMessage({ id: "month", lang })}
          </Typography>
        </Flex>
        <Typography
          color="grays.subtitle"
          fontSize="14px"
          fontWeight="500"
          mb={3}
          textAlign="center"
        >
          {discountText}
        </Typography>
        {showPaymentActions ? (
          <>
            <Button
              style={{ borderRadius: 4, width: "100%", padding: "16px 24px" }}
              isLoading={activePlanId === id && activePaymentMethod === "CARD"}
              disabled={!!activePlanId}
              onClick={() => handlePlanSelection("CARD")}
              variant="clear"
            >
              {formatMessage({ id: "payWithCreditCard", lang })}
            </Button>
            {paymentMethods &&
              paymentMethods.map(paymentMethod => (
                <Button
                  style={{ borderRadius: 4, width: "100%" }}
                  disabled={!!activePlanId}
                  key={paymentMethod}
                  mt={1}
                  px={3}
                  py={2}
                  onClick={() => handlePlanSelection(paymentMethod)}
                  variant="clear"
                  isLoading={
                    activePlanId === id && paymentMethod === activePaymentMethod
                  }
                >
                  {`${formatMessage({
                    id: "payWithPaymentMethod",
                    lang,
                  })} ${paymentMethod.toLowerCase()}`}
                </Button>
              ))}
          </>
        ) : (
          showSignupButton && (
            <Button
              style={{ width: "100%" }}
              isLoading={false}
              onClick={onPlanSelection}
              variant="clear"
            >
              {formatMessage({ id: "signUp", lang })}
            </Button>
          )
        )}
      </Flex>
    </Flex>
  )
}

export default React.forwardRef(Plan)
