import { lighten, darken } from "polished"

const SUCCESS = "hsla(136, 65%, 45%, 0.9)"
const ERROR = "hsl(0, 71%, 45%)"
const WHITE = "#ffffff"
const BLACK = "#000000"

const colors = () => {
  let primaryColor = "#e3f2fd"
  // let secondaryColor = "#808080";
  let secondaryColor = darken(0.05, primaryColor)
  let headerBackgroundColor = null
  let headerForegroundColor = null
  let primaryText = WHITE
  let secondaryText = WHITE

  const colors = {
    primary: primaryColor,
    primaryDim: darken(0.05, primaryColor),
    primaryLight: lighten(0.05, primaryColor),
    primaryText,
    secondary: secondaryColor,
    secondaryDim: darken(0.05, secondaryColor),
    secondaryLight: lighten(0.05, secondaryColor),
    secondaryText,
    headerBackgroundColor,
    headerForegroundColor,
    success: SUCCESS,
    successDim: darken(0.05, SUCCESS),
    successLight: lighten(0.05, SUCCESS),
    error: ERROR,
    errorDim: darken(0.05, ERROR),
    errorLight: lighten(0.05, ERROR),
    gold: "#a26809",
    background: "#f5f5f9",
    backgroundDark: "hsl(0, 0%, 32%)",
    warning: "#a26809",
    white: WHITE,
    white2: "#fafafa",
    whiteTransparent: "hsla(0,0%,100%,0.7)",
    whiteTransparent2: "hsla(0,0%,100%,0.2)",
    whiteTransparent3: "hsla(0, 0%, 100%, 0.1)",
    black: BLACK,
    blackTransparent: "rgba(0, 0, 0, 0.6)",
    blackTransparent2: "rgba(4, 4, 15, 0.4)",
    blackTransparent3: "rgba(0, 0, 0, 0.25)",
    transparent: "transparent",
    borderTransparent: "hsla(0, 0%, 32%, 0.1)",
    grays: [
      "hsl(0, 0%, 96%)",
      "hsl(0, 0%, 86%)",
      "hsl(0, 0%, 62%)",
      "hsl(0, 0%, 60%)",
      "hsl(0, 0%, 38%)",
      "hsl(0, 0%, 29%)",
      "hsl(240, 2%, 57%)",
    ],
  }

  colors.border = colors.grays[1]
  colors.separator = colors.grays[0]

  return colors
}

export default colors
