import React, { useState, useEffect, useRef } from "react"
import LangContext from "@contexts/lang"
import Flex from "../Flex"
import Select from "../Select"
import Plan from "./Plan"
import Typography from "../Typography"
import Box from "../Box"
import { css } from "@xstyled/emotion"
import { scrollToPlan } from "@utils/util"
import EnterprisePlan from "./EnterprisePlan"
import TapPaymentInfo from "./TapPaymentInfo"
import formatMessage from "@utils/texts"
import s from "./PlansList.module.scss"
import useTranslateField from "@hooks/useTranslateField"

export default function PlansList({
  activePaymentMethod,
  activePlanId,
  country,
  locale,
  onPlanSelection,
  plans,
  showCountrySelect,
  showPlansActions = "true",
  ...props
}) {
  const [planCountry, setPlansCountry] = useState(country)
  const [plansRefs, setPlansRefs] = useState(null)
  const plansContainerRef = useRef()
  const { lang } = React.useContext(LangContext)
  const translateField = useTranslateField()

  const filteredPlans = plans
    ? plans.filter(
      ({ countryCode, showOnWebsite }) =>
        countryCode === planCountry &&
        showOnWebsite
    )
    : []

  useEffect(() => {
    const defaultSelectedPlanId =
      filteredPlans && filteredPlans[0] ? filteredPlans[0].id : 0
    if (plansRefs && plansRefs[defaultSelectedPlanId]) {
      scrollToPlan({
        containerRef: plansContainerRef,
        ref: plansRefs[defaultSelectedPlanId],
      })
    }
  }, [planCountry])

  useEffect(() => {
    setPlansRefs(
      plans.reduce((refs, { id }) => ({ ...refs, [id]: React.createRef() }), {})
    )
  }, [plans])

  useEffect(() => {
    setPlansCountry(country)
  }, [country])

  function handlePlanCountryChange(selectedCountry) {
    setPlansCountry(selectedCountry)
  }

  return (
    <Flex as="section" flexDirection="column" {...props}>
      <Flex mb={2}>
        {showCountrySelect && (
          <Flex
            alignItems={["start", "center"]}
            flexDirection={["column", "row"]}
          >
            <Typography mb={[1, 0]} mr={[0, 1]} ml={[0, 1]} textStyle="body">
              {formatMessage({ id: "country", lang })}
            </Typography>
            <Select
              placeholder="country"
              name="country"
              mb={[1, 0]}
              onChange={handlePlanCountryChange}
              value={planCountry}
              minWidth="109px"
            >
              <Select.Option value="BH">
                {formatMessage({ id: "bahrain", lang })}
              </Select.Option>
              <Select.Option value="EG">
                {formatMessage({ id: "egypt", lang })}
              </Select.Option>
              <Select.Option value="KW">
                {formatMessage({ id: "kuwait", lang })}
              </Select.Option>
              <Select.Option value="OM">
                {formatMessage({ id: "oman", lang })}
              </Select.Option>
              <Select.Option value="QA">
                {formatMessage({ id: "qatar", lang })}
              </Select.Option>
              <Select.Option value="SA">
                {formatMessage({ id: "ksa", lang })}
              </Select.Option>
              <Select.Option value="AE">
                {formatMessage({ id: "uae", lang })}
              </Select.Option>
            </Select>
          </Flex>
        )}
      </Flex>
      <Box mx={-2} ref={plansContainerRef}>
        <Flex className={s.plansListContainer}>
          {filteredPlans.map(
            (
              {
                currency: { titleAr: currencyUnitAr, titleEn: currencyUnitEn },
                descriptionAr,
                descriptionEn,
                discountTextAr,
                discountTextEn,
                featuresAr,
                featuresEn,
                interval,
                paymentMethods,
                price,
                id,
                titleAr,
                titleEn,
              },
              idx
            ) => {
              return (
                <Plan
                  activePaymentMethod={activePaymentMethod}
                  activePlanId={activePlanId}
                  currencyUnit={translateField(currencyUnitAr, currencyUnitEn)}
                  mx="12px"
                  css={css`
                    &:first-of-type {
                      margin-left: 0 !important;
                    }
                    &:last-of-type {
                      margin-right: 0 !important;
                    }
                    @media (max-width: 768px) {
                      margin-left: 0;
                      margin-right: 0;
                    }
                  `}
                  margin={["0", "12px"]}
                  descriptionEn={descriptionEn}
                  description={translateField(descriptionAr, descriptionEn)}
                  discountText={translateField(discountTextAr, discountTextEn)}
                  features={translateField(featuresAr, featuresEn)}
                  flex={1}
                  interval={interval}
                  key={id}
                  locale={locale}
                  maxWidth={["350px", "282px", "unset"]}
                  onPlanSelection={({ paymentMethod }) =>
                    onPlanSelection({
                      id,
                      title: titleEn,
                      interval,
                      paymentMethod,
                      price,
                      currencyCode: currencyUnitEn,
                      planCountry,
                    })
                  }
                  paymentMethods={paymentMethods}
                  previousPlanTitle={
                    filteredPlans &&
                    idx > 0 &&
                    filteredPlans[idx - 1] &&
                    translateField(
                      filteredPlans[idx - 1].titleAr,
                      filteredPlans[idx - 1].titleEn
                    )
                  }
                  price={price}
                  ref={plansRefs && plansRefs[id]}
                  showPaymentActions={showPlansActions}
                  id={id}
                  title={translateField(titleAr, titleEn)}
                  width={["100%", "80vw", "auto"]}
                />
              )
            }
          )}
        </Flex>
      </Box>
      <EnterprisePlan />
      <TapPaymentInfo mt="3" />
    </Flex>
  )
}
