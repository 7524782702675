import React, { useMemo, useState } from "react"
import LangContext from "@contexts/lang"
import ArrowDownIcon from "@images/arrow-down.svg"
import Box from "./Box"
import s from "./Select.module.scss"

const Select = ({
  children,
  onChange,
  value: initialValue,
  placeholder,
  value,
  ...props
}) => {
  const { lang } = React.useContext(LangContext)
  const [isOpen, setIsOpen] = useState(false)

  const selectedOptionPosition = useMemo(() => {
    return children
      ? children.findIndex(
          ({ props: { value: childValue } = {} }) => childValue === value
        )
      : 0
  }, [children, value])

  const [tempSelectedOptionPosition, setTempSelectedOptionPosition] = useState(
    selectedOptionPosition
  )

  function hanldeKeyDown(event) {
    // TODO: add debouncing
    const listLength = children ? children.length : 0

    switch (event.key) {
      case "ArrowDown":
        event.preventDefault()
        if (isOpen) {
          if (tempSelectedOptionPosition < listLength - 1) {
            setTempSelectedOptionPosition(tempSelectedOptionPosition + 1)
          } else {
            setTempSelectedOptionPosition(0)
          }
        } else {
          toggleOpen()
        }
        break
      case "ArrowUp":
        if (isOpen) {
          event.preventDefault()
          if (tempSelectedOptionPosition > 0) {
            setTempSelectedOptionPosition(tempSelectedOptionPosition - 1)
          } else {
            setTempSelectedOptionPosition(listLength - 1)
          }
        } else {
          toggleOpen()
        }
        break
      case "Escape":
        setIsOpen(false)
        break
      case "Enter":
      case " ":
        event.preventDefault()
        if (isOpen) {
          setIsOpen(false)
          if (
            children &&
            children[tempSelectedOptionPosition] &&
            children[tempSelectedOptionPosition]
          ) {
            onChange(children[tempSelectedOptionPosition].props.value)
          }
        } else {
          toggleOpen()
        }
        break
      default:
        break
    }
  }

  function handleChange(e) {
    onChange && onChange(e.target.value)
  }

  function handleBlur() {
    setIsOpen(!isOpen)
  }

  function handleClick() {
    toggleOpen()
  }

  function toggleOpen() {
    if (!isOpen) {
      setTempSelectedOptionPosition(selectedOptionPosition)
    }
    setIsOpen(!isOpen)
  }

  const headerText = useMemo(() => {
    const { props: { children: text = "" } = {} } =
      children.find(
        ({ props: { value: childValue } = {} }) => childValue === value
      ) || {}
    return text
  }, [children, value])

  return (
    <Box
      className={`${s.select} ${isOpen && s.isClosed}`}
      placeholder={placeholder}
      onBlur={handleBlur}
      onChange={handleChange}
      onClick={handleClick}
      onKeyDown={hanldeKeyDown}
      {...props}
    >
      <div className={s.header}>
        <span>{headerText}</span>
        <ArrowDownIcon
          className={`${s.arrowDownIcon} ${lang === "ar" && s.flipped}`}
        />
      </div>
      <ul className={`${s.options}`}>
        {React.Children.map(children, (child, idx) => {
          return (
            <SelectOption
              onClick={() => onChange(child.props.value)}
              isActive={idx === tempSelectedOptionPosition}
              isSelected={idx === selectedOptionPosition}
            >
              {child.props.children}
            </SelectOption>
          )
        })}
      </ul>
    </Box>
  )
}

const SelectOption: React.FC<{
  isActive?: boolean
  isSelected?: boolean
  value?: string | number
  onClick?
}> = ({ isActive = false, isSelected, children, onClick }) => {
  return (
    <li
      onClick={onClick}
      className={`${s.selectOption} ${isActive && s.isActive} ${isSelected &&
        s.isSelected}`}
    >
      {children}
    </li>
  )
}

Select.Option = SelectOption

export default Select
