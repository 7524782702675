import React from "react"
import LangContext from "@contexts/lang"
import Flex from "../Flex"
import { CONTACT_NUMBER } from "@utils/constants"
import Typography from "../Typography"
import Feature from "./Feature"
import Button from "../Button"
import formatMessage from "@utils/texts"

export default function EnterprisePlan() {
  const { lang } = React.useContext(LangContext)
  return (
    <Flex
      style={{
        boxShadow: "0 1px 4px 1px rgba(207, 216, 220, 0.33)",
        marginTop: 16,
        padding: "16px 24px",
      }}
      flexDirection={["column", "row"]}
    >
      <Flex flexDirection="column">
        <Typography textStyle="heading">
          {formatMessage({ id: "enterprise", lang })}
        </Typography>
        <Flex mt={1} flexDirection="column">
          <Typography
            color="grays.subtitle"
            fontSize="14px"
            fontWeight="500"
            mb={2}
          >
            {formatMessage({ id: "entBody", lang })}
          </Typography>
          <Feature mb={2}>{formatMessage({ id: "entF1", lang })}</Feature>
          <Feature mb={2}> {formatMessage({ id: "entF2", lang })}</Feature>
          <Feature mb={2}> {formatMessage({ id: "entF3", lang })}</Feature>
        </Flex>
      </Flex>
      <Button
        isLoading={false}
        style={lang === "en" ? 
          { alignSelf: "start", marginLeft: "auto" } : 
          { alignSelf: "start", marginRight: "auto" }
        }
        as="a"
        href={"tel:" + CONTACT_NUMBER}
        ml={[0, 3, "auto"]}
        variant="clear"
      >
        {formatMessage({ id: "contactUs", lang })}
      </Button>
    </Flex>
  )
}
