import React from "react"
import LangContext from "@contexts/lang"

export default function useTranslateField() {
  const { lang } = React.useContext(LangContext)
  
  function translateField(fieldAr, fieldEn) {
    switch (lang) {
      case "ar":
        return fieldAr || ""

      default:
        return fieldEn || ""
    }
  }
  return translateField
}
