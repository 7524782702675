import React from "react"
import { useUp } from "@xstyled/emotion"
import LangContext from "@contexts/lang"
import Flex from "../Flex"
import Box from "../Box"
import TapLogoSvg from "@images/tap-logo.svg"
import KnetLogoSvg from "@images/knet-logo.svg"
import MadaLogoSvg from "@images/mada-logo.svg"
import MastercardLogoSvg from "@images/mastercard-logo.svg"
import VisaLogoSvg from "@images/visa-logo.svg"
import Typography from "../Typography"
import Grid from "../Grid"
import formatMessage from "@utils/texts"

export default function TapPaymentInfo({ ...props }) {
  const { lang } = React.useContext(LangContext)
  const showHeader = useUp("md")
  return (
    <Flex
      border="1px solid"
      borderColor="border"
      borderRadius="4px"
      p={3}
      {...props}
      flexDirection={["column", "row"]}
      justifyContent="space-between"
    >
      <Flex mb={[2, 0]} minWidth="100px">
        <TapLogoSvg 
          style={lang === "en" ? 
            { width: 30, height: 48, marginRight: 16 } : 
            { width: 30, height: 48, marginLeft: 16 }
          } 
        />
        <Flex flexDirection="column" maxWidth={["100%", "120px"]}>
          <Typography lineHeight="1.5" mb={1} textStyle="heading">
            {formatMessage({ id: "paymentMethodsFees", lang })}
          </Typography>
          <Typography color="grays.subtitle" fontSize="14px" fontWeight="500">
            {formatMessage({ id: "paymentMethodsDescription", lang })}
          </Typography>
        </Flex>
      </Flex>
      <Grid
        flex={1}
        gridColumnGap={[4, 2]}
        gridRowGap={1}
        gridTemplateColumns={["1fr 1fr", "1fr 1fr 1fr"]}
        ml={[0, 2]}
      >
        {showHeader && (
          <>
            <Typography
              color="grays.subtitle"
              fontSize="14px"
              fontWeight="500"
              mb={2}
            >
              {formatMessage({ id: "paymentMethod", lang })}
            </Typography>
            <Typography
              color="grays.subtitle"
              fontSize="14px"
              fontWeight="500"
              mb={2}
            >
              {formatMessage({ id: "settlementWindow", lang })}
            </Typography>
            <Typography
              color="grays.subtitle"
              fontSize="14px"
              fontWeight="500"
              mb={2}
            >
              {formatMessage({ id: "fees", lang })}
            </Typography>
          </>
        )}
        <Flex>
          <MastercardLogoSvg
            style={lang === "en" ? 
            { marginRight: 8, width: 24, height: 16 } : 
            { marginLeft: 8, width: 24, height: 16 }
          }
          />
          <Typography textStyle="body">
            {formatMessage({ id: "mastercard", lang })}
          </Typography>
        </Flex>
        <Typography textAlign={["end", "initial"]} textStyle="body">
          5 {formatMessage({ id: "workingDays", lang })}
        </Typography>
        <Typography textStyle="body" gridColumn={["1 / 3", "3"]} pb={[1, 0]}>
          {formatMessage({ id: "transactionInfoCreditCard", lang })}
        </Typography>

        <Flex>
          <VisaLogoSvg 
            style={lang === "en" ? 
              { marginRight: 8, width: 24, height: 16 } : 
              { marginLeft: 8, width: 24, height: 16 }
            } 
          />
          <Typography textStyle="body">
            {formatMessage({ id: "visa", lang })}
          </Typography>
        </Flex>
        <Typography textAlign={["end", "initial"]} textStyle="body">
          5 {formatMessage({ id: "workingDays", lang })}
        </Typography>
        <Typography gridColumn={["1 / 3", "3"]} pb={[1, 0]} textStyle="body">
          {formatMessage({ id: "transactionInfoCreditCard", lang })}
        </Typography>

        <Flex>
          <KnetLogoSvg
            style={lang === "en" ? 
              { marginRight: 8, width: 24, height: 16 } : 
              { marginLeft: 8, width: 24, height: 16 }
            } 
          />
          <Typography textStyle="body">
            {formatMessage({ id: "knet", lang })}
          </Typography>
        </Flex>
        <Typography textAlign={["end", "initial"]} textStyle="body">
          3 {formatMessage({ id: "workingDays", lang })}
        </Typography>
        <Typography gridColumn={["1 / 3", "3"]} textStyle="body">
          {formatMessage({ id: "transactionInfoKnet", lang })}
        </Typography>

        <Flex>
          <MadaLogoSvg 
            style={lang === "en" ? 
              { marginRight: 8, width: 24, height: 16 } : 
              { marginLeft: 8, width: 24, height: 16 }
            } 
          />
          <Typography textStyle="body">
            {formatMessage({ id: "mada", lang })}
          </Typography>
        </Flex>
        <Typography textAlign={["end", "initial"]} textStyle="body">
          3 {formatMessage({ id: "workingDays", lang })}
        </Typography>
        <Typography gridColumn={["1 / 3", "3"]} textStyle="body">
          {formatMessage({ id: "transactionInfoMada", lang })}
        </Typography>
      </Grid>
    </Flex>
  )
}
