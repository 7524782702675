import React, { useState } from "react"
import { graphql } from "gatsby"
import LangContext from "@contexts/lang"
import * as Sentry from "@sentry/browser"
import axios from "axios"
import Flex from "@components/globals/Flex"
import PlansList from "@components/globals/Plans/PlansList"
import Typography from "@components/globals/Typography"
import { BE_URL } from "@utils/constants"
import SEO from "@components/common/seo/seo"
import formatMessage from "@utils/texts"
import ThemeProvider from "@theme/ThemeProvider"
import s from "./payment.module.scss"

export default function PaymentPage({
  data: {
    plans: { plans },
  },
  location,
}) {
  const { lang, countryCode: country } = React.useContext(LangContext)
  const [activePaymentMethod, setActivePaymentMethod] = useState(false)
  const [hasMounted, setHasMounted] = useState(false)
  const [activePlanId, setActivePlanId] = useState()

  React.useEffect(() => {
    setHasMounted(true)
  }, [])

  const { state } = location
  const { email, firstName, lead, lastName, num, organizationName } =
    state || {}

  const handlePlanSelection = async ({ id: planId, paymentMethod }) => {
    const data = {
      attributes: {
        lead_id: lead,
        country_plan_id: planId,
        mobile: num,
        payment_method: paymentMethod,
        email,
        first_name: firstName,
        last_name: lastName,
        language: lang === "en" ? "english" : "arabic",
        organization_name: organizationName,
      },
    }
    setActivePaymentMethod(paymentMethod)
    setActivePlanId(planId)
    try {
      const { data: { url } = {} } = await axios.post(
        `${BE_URL}/plan_payment`,
        {
          data,
        }
      )
      if (url) {
        hasMounted && window.location.assign(url)
      }
    } catch (error) {
      Sentry.captureException(error)
      setActivePaymentMethod(true)
    }
  }

  return (
    <>
      <SEO title={formatMessage({ id: "payment", lang })} location={location} />
      <ThemeProvider direction={lang === "ar" ? "rtl" : "ltr"}>
        <Flex className={s.container} flexDirection="column">
          <Typography textStyle="heading5" mb={3}>
            {formatMessage({ id: "choosePlan", lang })}
          </Typography>
          <PlansList
            showCountrySelect
            activePaymentMethod={activePaymentMethod}
            activePlanId={activePlanId}
            country={country}
            locale={lang}
            plans={plans}
            onPlanSelection={handlePlanSelection}
          />
        </Flex>
      </ThemeProvider>
    </>
  )
}

export const siteQuery = graphql`
  query SiteQuery {
    plans {
      plans {
        currency {
          titleAr
          titleEn
        }
        showOnWebsite
        countryCode
        descriptionAr
        descriptionEn
        discountTextAr
        discountTextEn
        featuresAr
        featuresEn
        id
        interval
        paymentMethods
        position
        price
        titleAr
        titleEn
      }
    }
  }
`
