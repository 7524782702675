export function pricePerMonth(interval, price) {
  switch (interval) {
    case "annual":
      return (price / 12).toFixed(1);
    case "quarter":
      return (price / 3).toFixed(1);
    case "month":
      return price.toFixed(1);
    default:
      return price.toFixed(1);
  }
}
