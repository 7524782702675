import Geocode from "react-geocode"
import { GMAPS_KEY } from "./constants"

Geocode.setApiKey(GMAPS_KEY)

export const scrollToPlan = ({ containerRef, ref, behavior = "smooth" }) => {
  const container = (containerRef && containerRef.current) || window
  const marginLeft = 15
  try {
    ref &&
      ref.current &&
      container.scrollTo({
        left:
          ref.current.getBoundingClientRect().left +
          container.scrollLeft -
          marginLeft,
        behavior,
      })
  } catch (e) {
    ref &&
      ref.current &&
      container.scrollTo(
        ref.current.getBoundingClientRect().left +
          container.scrollLeft -
          marginLeft
      )
  }
}

export const currencyByCountryCode = {
  en: {
    BH: "BHD",
    EG: "EGP",
    JO: "JOD",
    KW: "KWD",
    OM: "OMR",
    QA: "QAR",
    SA: "SAR",
    AE: "AED",
  },
  ar: {
    BH: "BHD",
    EG: "EGP",
    JO: "JOD",
    KW: "KWD",
    OM: "OMR",
    QA: "QAR",
    SA: "SAR",
    AE: "AED",
  },
}

const countryCodeByCountry = {
  Bahrain: "BH",
  Egypt: "EG",
  Jordan: "JO",
  Kuwait: "KW",
  Oman: "OM",
  QATAR: "QA",
  KSA: "SA",
  UAE: "UAE",
}

export const countryByCountryCode = {
  BH: "Bahrain",
  EG: "Egypt",
  JO: "Jordan",
  KW: "Kuwait",
  OM: "Oman",
  QA: "Qatar",
  SA: "KSA",
  UAE: "UAE",
}
