import React from "react"
import Flex from "../Flex"
import Typography from "../Typography"
import CheckSvg from "@images/check.svg"

type Props = {
  children?
  flip?
  flipIconInRtl?
  iconSrc?
  textStyle?
  mb?
}

const Feature: React.FC<Props> = ({
  children,
  flipIconInRtl,
  iconSrc,
  textStyle,
  ...props
}) => {
  return (
    <Flex as="li" mb={2} {...props}>
      <CheckSvg style={{ height: 16, width: 16, marginBottom: 8 }} />
      <Typography fontSize="14px" lineHeight="22px" mt="-2px" {...textStyle}>
        {children}
      </Typography>
    </Flex>
  )
}

export default Feature
