import { variant } from "@xstyled/system"
import { generateColors } from "./variables"
import { css } from "@emotion/core"

export const buttonSize = variant`{
  prop: "buttonSize",
  key: "buttonSizes",
}`

export const iconSize = variant({
  prop: "iconSize",
  key: "iconSizes",
})

export const iconStyles = variant({
  prop: "variant",
  key: "iconStyles",
})

export const spinnerSize = variant({
  prop: "spinnerSize",
  key: "spinnerSizes",
})

export const spinnerStyles = variant({
  prop: "variant",
  key: "spinnerStyles",
})

export const pillStyles = variant({
  prop: "variant",
  key: "pillStyles",
})

export const placeholderStyles = variant({
  prop: "variant",
  key: "placeholderStyles",
})

export const linkStyles = variant({
  prop: "variant",
  key: "linkStyles",
})

export const notificationSize = variant({
  prop: "notificationSize",
  key: "notificationSizes",
})

export const notificationStyles = variant({
  prop: "variant",
  key: "notificationStyles",
})

export const backgroundGradients = variant({
  prop: "backgroundGradient",
  key: "backgroundGradients",
})

const arabicDefaultFonts =
  'gulf, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif'
const englishDefaultFonts =
  'Myriad Set Pro, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif'

const theme = () => {
  const colors = generateColors()

  const baseTheme = {
    //  The breakpoints are the same as the ones
    // we used in the previous desing before (default Material-UI breakpoints)
    breakpoints: [0, 600, 960, 1280, 1920],
    // breakpoints: [32, 48, 64],
    // breakpoints: {
    //   xs: 0,
    //   sm: "576px",
    //   md: "768px",
    //   lg: "992px",
    //   xl: "1200px",
    // },

    // The design follow an 8px grid point
    space: [0, 8, 16, 24, 32, 40, 48, 56, 64, 72, 80, 128, 256, 512],
    sizes: [0, 8, 16, 24, 32, 40, 48, 56, 64, 128, 256, 512, 768, 1024, 1536],

    fontSizes: [12, 15, 17, 22, 24, 32, 36, 40, 48, 80, 96],
    // fontWeights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    fontWeights: {
      "100": 100,
      light: 100,
      "200": 200,
      "300": 300,
      "400": 400,
      normal: 400,
      "500": 500,
      "600": 600,
      bold: 600,
      "700": 700,
      "800": 800,
      "900": 900,
      boldest: 900,
    },
    lineHeights: {
      small: 1.33,
      body: 1.33,
      heading1: 1.2,
      heading7: 1.14,
      display: 1.27,
    },
    // lineHeights: [0, 1.23, 1.27, 1.33, 1.41],
    letterSpacings: {
      tight: "-0.1px",
      normal: "-0.2px",
      wide: "-0.4px",
      mega: "0.3px",
    },
    // letterSpacings: [0, "-0.1px", "-0.2px", "-0.4px", "0.3px"],
    fonts: {
      arabic: arabicDefaultFonts,
      english: englishDefaultFonts,
    },
    borders: [
      0,
      "1px solid",
      "2px solid",
      "4px solid",
      "8px solid",
      "16px solid",
      "32px solid",
    ],
    outline: `2px dashed ${colors.grays[1]}`,
    radii: [0, 2, 4, 6, 8, 10, 12, 14, 16, "50%"],
    zIndices: [-1, 1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000],
    shadows: {
      boxShadow: "0 1px 4px 1px rgba(207, 216, 220, 0.33)",
    },
  }

  baseTheme.breakpoints.xs = baseTheme.breakpoints[0]
  baseTheme.breakpoints.sm = baseTheme.breakpoints[1]
  baseTheme.breakpoints.md = baseTheme.breakpoints[2]
  baseTheme.breakpoints.lg = baseTheme.breakpoints[3]
  baseTheme.breakpoints.xl = baseTheme.breakpoints[4]

  baseTheme.space[0.25] = baseTheme.space[1] / 4
  baseTheme.space.quarter = baseTheme.space[1] / 4

  baseTheme.space[0.5] = baseTheme.space[1] / 2
  baseTheme.space.half = baseTheme.space[1] / 2

  baseTheme.sizes.layoutSm = "480px"
  baseTheme.sizes.layoutMd = "960px"
  baseTheme.sizes.layoutLg = "1200px"

  baseTheme.sizes[0.25] = baseTheme.sizes[1] / 4
  baseTheme.sizes.quarter = baseTheme.sizes[1] / 4

  baseTheme.sizes[0.5] = baseTheme.sizes[1] / 2
  baseTheme.sizes.half = baseTheme.sizes[1] / 2

  baseTheme.radii.rounded = baseTheme.radii[9]

  baseTheme.fontSizes.display = baseTheme.fontSizes[3]
  baseTheme.fontSizes.heading = 18
  baseTheme.fontSizes.title = 24
  baseTheme.fontSizes.heading1 = 32.2
  baseTheme.fontSizes.heading5 = 24
  baseTheme.fontSizes.heading6 = 18
  baseTheme.fontSizes.heading7 = 28
  baseTheme.fontSizes.body = 16
  baseTheme.fontSizes.small = baseTheme.fontSizes[0]

  return {
    ...baseTheme,
    colors: {
      ...colors,
      black: "#212529",
      borderError: "#c63024",
      borderFocus: "#23276b",
      errorBackground: "#ffeff1",
      error: "#e53935",
      grays: {
        border: "#e0dde3",
        footer: "#2c2930",
        placeholder: "#9ba2ab",
        subtitle: "#8c8398",
      },
      lightGrey: "#f2f2f2",
      primary: "#49ba7a",
    },
    textStyles: {
      display: css`
        font-size: ${baseTheme.fontSizes.display}px;
        font-weight: ${baseTheme.fontWeights.normal};
        line-height: ${baseTheme.lineHeights.display};
        letter-spacing: ${baseTheme.letterSpacings.mega};
      `,
      heading: css`
        font-size: ${baseTheme.fontSizes.heading}px;
        font-weight: ${baseTheme.fontWeights["bold"]};
      `,
      title: css`
        font-size: ${baseTheme.fontSizes.title}px;
        font-weight: ${baseTheme.fontWeights["bold"]};
      `,
      heading1: css`
        font-size: ${baseTheme.fontSizes.heading1}px;
        font-weight: ${baseTheme.fontWeights[700]};
        line-height: ${baseTheme.lineHeights.heading1};
        letter-spacing: ${baseTheme.letterSpacings.wide};
      `,
      heading5: css`
        font-size: ${baseTheme.fontSizes.heading5}px;
        font-weight: ${baseTheme.fontWeights["bold"]};
        line-height: ${baseTheme.lineHeights.heading5};
        letter-spacing: ${baseTheme.letterSpacings.wide};
      `,
      heading6: css`
        font-size: ${baseTheme.fontSizes.heading6}px;
        font-weight: ${baseTheme.fontWeights[500]};
        line-height: ${baseTheme.lineHeights.heading6};
        letter-spacing: ${baseTheme.letterSpacings.wide};
      `,
      heading7: css`
        font-size: ${baseTheme.fontSizes.heading7}px;
        font-weight: ${baseTheme.fontWeights[500]};
        line-height: ${baseTheme.lineHeights.heading7};
        letter-spacing: 0;
      `,
      body: css`
        font-size: ${baseTheme.fontSizes.body}px;
        font-weight: ${baseTheme.fontWeights.normal};
        line-height: 1.25;
        letter-spacing: ${baseTheme.letterSpacings.normal};
      `,
      bodyBold: css`
        font-size: ${baseTheme.fontSizes.body}px;
        font-weight: ${baseTheme.fontWeights[600]};
        line-height: 1.25;
        letter-spacing: ${baseTheme.letterSpacings.normal};
      `,
      small: css`
        font-size: ${baseTheme.fontSizes.small}px;
        font-weight: ${baseTheme.fontWeights.normal};
        line-height: ${baseTheme.lineHeights.small};
      `,
    },
    buttonSizes: {
      small: css`
        font-size: ${baseTheme.fontSizes[0]}px;
        letter-spacing: ${baseTheme.letterSpacings.tight};
        line-height: ${baseTheme.lineHeights.small};
        padding: ${baseTheme.space[1]}px ${baseTheme.space[2]}px;
        min-height: ${baseTheme.sizes[4]}px;
      `,
      normal: css`
        font-size: ${baseTheme.fontSizes[1]}px;
        letter-spacing: ${baseTheme.letterSpacings.normal};
        line-height: ${baseTheme.lineHeights.display};
        min-height: ${baseTheme.sizes[6]}px;
        padding: 14px 16px;
      `,
    },
    backgroundGradients: {
      blackTransparent: css`
        background-image: linear-gradient(
          ${colors.blackTransparent},
          ${colors.transparent}
        );
      `,
      white: css`
        background-image: linear-gradient(${colors.white}, ${colors.white});
      `,
    },
    iconStyles: {
      default: css`
        color: currentColor;
        &:hover:enabled,
        &:focus {
          outline: none;
          opacity: 0.65;
        }
      `,
      primary: css`
        color: ${colors.primary};
        &:hover:enabled,
        &:focus {
          outline: none;
          color: ${colors.primaryDim};
        }
      `,
      primaryText: css`
        color: ${colors.primaryText};
        &:hover:enabled,
        &:focus {
          outline: none;
          opacity: 0.85;
        }
      `,
      secondary: css`
        color: ${colors.secondary};
        &:hover:enabled,
        &:focus {
          outline: none;
          color: ${colors.secondaryDim};
        }
      `,
      danger: css`
        color: ${colors.error};
        &:hover:enabled,
        &:focus {
          outline: none;
          color: ${colors.errorDim};
        }
      `,
      black: css`
        color: ${colors.black};
        &:hover:enabled,
        &:focus {
          outline: none;
          color: ${colors.grays[4]};
        }
      `,
      white: css`
        color: ${colors.white};
        &:hover:enabled,
        &:focus {
          outline: none;
          color: ${colors.grays[1]};
        }
      `,
    },
    iconSizes: {
      small: css`
        font-size: ${baseTheme.fontSizes[1]}px;
        height: ${baseTheme.fontSizes[1]}px;
      `,
      medium: css`
        font-size: ${baseTheme.fontSizes[4]}px;
        height: ${baseTheme.fontSizes[4]}px;
      `,
      heading: css`
        font-size: ${baseTheme.fontSizes[5]}px;
        height: ${baseTheme.fontSizes[5]}px;
      `,
      display: css`
        font-size: ${baseTheme.fontSizes[7]}px;
        height: ${baseTheme.fontSizes[7]}px;
      `,
      hero: css`
        font-size: 160px;
        height: 160px;
      `,
    },
    spinnerSizes: {
      small: css`
        width: 12px;
        height: 12px;
        padding: ${baseTheme.space[1] / 4}px;
      `,
      large: css`
        width: 24px;
        height: 24px;
        padding: ${baseTheme.space.half}px;
      `,
    },
    spinnerStyles: {
      dark: css`
        border: ${baseTheme.borders[1]} ${colors.white};
        border-right-color: ${colors.black};
      `,
      white: css`
        border: ${baseTheme.borders[1]} ${colors.borderTransparent};
        border-right-color: ${colors.black};
      `,
      btn: css`
        border: ${baseTheme.borders[1]} ${colors.borderTransparent};
        border-right-color: ${colors.primary};
      `,
      darkBtn: css`
        border: ${baseTheme.borders[1]} ${colors.whiteTransparent2};
        border-right-color: ${colors.primaryText};
      `,
    },
    pillStyles: {
      default: css`
        color: ${colors.white};
        background-color: ${colors.grays[3]};
      `,
      primary: css`
        color: ${colors.primaryText};
        background-color: ${colors.primary};
      `,
      secondary: css`
        color: ${colors.secondaryText};
        background-color: ${colors.secondary};
      `,
      danger: css`
        color: ${colors.white};
        background-color: ${colors.error};
      `,
      success: css`
        color: ${colors.white};
        background-color: ${colors.gold};
      `,
    },
    placeholderStyles: {
      display: css`
        width: 40%;
        height: 14px;
      `,
      header: css`
        height: ${baseTheme.sizes[4]}px;
        width: 100%;
        margin-bottom: ${baseTheme.space[2]}px;
      `,
      image: css`
        border-radius: ${baseTheme.radii[2]}px;
        height: 92px;
        width: 92px;
      `,
      button: css`
        border: ${baseTheme.borders[2]};
        border-radius: ${baseTheme.radii[2]}px;
        width: 144px;
        height: ${baseTheme.sizes[4]}px;
      `,
      text: css`
        width: 100%;
        height: ${baseTheme.sizes[1]}px;
        margin-bottom: ${baseTheme.space[1]}px;
      `,
    },
    notificationStyles: {
      default: css`
        color: ${colors.white};
        background-color: ${colors.grays[5]};
      `,
      error: css`
        color: ${colors.white};
        background-color: ${colors.error};
      `,
      success: css`
        color: ${colors.white};
        background-color: ${colors.success};
      `,
    },
    notificationSizes: {
      small: css`
        padding: ${baseTheme.space.half}px;
      `,
      default: css`
        padding: ${baseTheme.space[1] + baseTheme.space.half}px;
      `,
    },
  }
}

export default theme
