import rtlCSS from "rtlcss";

// for more info: https://github.com/thysultan/stylis.js#plugins
const STYLIS_CONTEXTS = {
  POST_PROCESS: -2,
  PREPARATION: -1,
  NEWLINE: 0,
  PROPERTY: 1,
  SELECTOR_BLOCK: 2,
  AT_RULE: 3,
};

const rtlPlugin = (context, content) => {
  if (context === STYLIS_CONTEXTS.PREPARATION) {
    return rtlCSS.process(content);
  }
};

export default rtlPlugin;
