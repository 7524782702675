import React from "react"
import { CacheProvider } from "@emotion/core"
import createCache from "@emotion/cache"
import { rtlPlugin } from "./variables"

const ThemeCacheProvider = ({ direction = "ltr", children }) => {
  const cache = {
    LTR: createCache({
      key: "ltr",
    }),
    RTL: createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    }),
  }

  const selectedCache = direction !== "rtl" ? cache.LTR : cache.RTL
  return <CacheProvider value={selectedCache}>{children}</CacheProvider>
}

export default ThemeCacheProvider
